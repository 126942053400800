@import url(https://fonts.googleapis.com/css?family=Cairo:200,300,400,600,700,900);
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #383e43;
  color: #929496;
  padding: 1rem 2rem; }
  .footer .content-link {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%; }
    .footer .content-link .links-info {
      margin: 0 0.1rem; }
      .footer .content-link .links-info .title {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0.5rem 0.1rem 0.5rem 0; }
      .footer .content-link .links-info .links {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        font-weight: 400; }
  .footer .terms {
    margin: 2rem 0;
    width: 100%;
    padding: 5rem 6rem;
    position: relative; }
    .footer .terms .terms-services {
      border-bottom: 2px solid #4d4d4f; }
      .footer .terms .terms-services .new-phone {
        font-size: 1.3rem; }
      .footer .terms .terms-services a {
        text-decoration: none;
        color: inherit; }
      .footer .terms .terms-services a:not(:last-child):after {
        padding: 0 1rem;
        font-size: 1.5rem;
        content: " | "; }
      .footer .terms .terms-services .terms-title {
        font-size: 1.5rem; }
      .footer .terms .terms-services .terms-title:not(:last-child):after {
        padding: 0 1rem;
        content: " | "; }
      .footer .terms .terms-services .afip-qr-container {
        position: absolute;
        right: 8rem;
        top: 0; }
        .footer .terms .terms-services .afip-qr-container .afip-qr {
          display: inline-block;
          margin-left: 20px; }
          .footer .terms .terms-services .afip-qr-container .afip-qr img {
            height: 100px; }
    .footer .terms .conditions {
      margin: 1rem 0; }
      .footer .terms .conditions p {
        margin: 0;
        font-size: 1rem;
        font-weight: 700; }

.footer-desktop .footer-link {
  text-decoration: none;
  color: #929496;
  font-size: 1rem; }
  .footer-desktop .footer-link span {
    font-size: 1.1rem;
    line-height: 1.3rem; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .terms {
    margin: 1rem 0 !important;
    width: 100%;
    padding: 4rem 0 !important;
    position: relative; }
    .terms .terms-services a:not(:last-child):after {
      font-size: 1.1rem !important; }
    .terms .terms-services .terms-title {
      font-size: 1.1rem !important; }
    .terms .terms-services .afip-qr-container {
      right: 1rem !important;
      top: 0; }
      .terms .terms-services .afip-qr-container .afip-qr {
        display: inline-block;
        margin-left: 10px; }
        .terms .terms-services .afip-qr-container .afip-qr img {
          height: 75px !important; }
    .terms .conditions {
      margin: 1rem 0; }
      .terms .conditions p {
        margin: 0;
        font-size: 1rem;
        font-weight: 700; } }

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .terms {
    padding: 2rem 0 !important; }
    .terms .terms-services .afip-qr-container .afip-qr img {
      height: 50px !important; } }

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #383e43;
  color: #929496;
  padding: 1rem 2rem;
  margin-top: -10px; }
  .footer .content-link {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%; }
    .footer .content-link .links-info {
      margin: 0 0.1rem 1rem 0;
      width: 100%; }
      .footer .content-link .links-info .afip-qr-container {
        position: absolute;
        right: 2rem;
        top: 0; }
        .footer .content-link .links-info .afip-qr-container .afip-qr {
          display: inline-block;
          margin-left: 20px;
          margin-top: 20px; }
          .footer .content-link .links-info .afip-qr-container .afip-qr img {
            height: 100px; }
      .footer .content-link .links-info .title {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0.5rem 0.1rem 0.5rem 0; }
      .footer .content-link .links-info .links {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-weight: 600; }
  .footer .terms {
    margin: 2rem 0 30px 0;
    width: 100%;
    padding: 5rem 6rem 3rem 0;
    position: relative; }
    .footer .terms p {
      margin: 20px 0 0 0;
      font-size: 18px;
      font-weight: 600; }
    .footer .terms .terms-services-mobile {
      flex-direction: column; }
      .footer .terms .terms-services-mobile .new-phone {
        color: #929496; }
        .footer .terms .terms-services-mobile .new-phone a {
          color: #929496; }
      .footer .terms .terms-services-mobile .terms-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 0.5rem;
        text-decoration: none;
        text-align: left;
        color: #929496; }

.footer-link {
  text-decoration: none;
  text-align: left;
  font-size: 20px;
  color: #929496; }

@media screen and (max-width: 400px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #383e43;
    color: #929496;
    padding: 1rem 2rem;
    min-height: 298px;
    margin-top: -2px; }
    .footer .content-link {
      display: flex;
      position: relative;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%; }
      .footer .content-link .links-info {
        margin: 0 2rem 1rem 0;
        width: 100%; }
        .footer .content-link .links-info .afip-qr-container {
          position: absolute;
          right: 0rem;
          top: 145px; }
          .footer .content-link .links-info .afip-qr-container .afip-qr {
            display: inline-block;
            margin-left: 20px;
            margin-top: 20px; }
            .footer .content-link .links-info .afip-qr-container .afip-qr img {
              height: 75px; }
        .footer .content-link .links-info .title {
          font-size: 1.4rem;
          font-weight: 600;
          margin: 0.5rem 0.5rem 0.5rem 0; }
        .footer .content-link .links-info .links {
          display: flex;
          flex-direction: column;
          text-align: left;
          font-weight: 600; }
    .footer .terms {
      margin: 2rem 0 30px 0;
      width: 100%;
      padding: 5rem 6rem 3rem 0;
      position: relative; }
      .footer .terms p {
        margin: 81px 0 0 0;
        font-size: 18px;
        font-weight: 600; }
      .footer .terms .terms-services-mobile {
        flex-direction: column; }
        .footer .terms .terms-services-mobile .terms-title {
          font-size: 17px;
          font-weight: 600;
          line-height: 0.5rem;
          text-decoration: none;
          text-align: left;
          color: #929496; } }

.section-button {
  margin: 0;
  height: 100px;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  align-content: center; }
  .section-button p {
    font-size: 2rem;
    width: 70%;
    line-height: 100px;
    margin: 0;
    color: #ffffff;
    font-weight: 600;
    position: relative;
    display: inline-block; }
  .section-button .side-img {
    width: 40px;
    height: auto; }
    .section-button .side-img.arrow {
      position: absolute;
      top: 35px;
      right: 20px;
      width: 30px; }
  .section-button .img {
    height: 70px;
    width: 70px;
    position: absolute;
    top: 15px;
    left: 30px; }

@media screen and (max-width: 470px) {
  .section-button {
    margin: 0;
    height: 100px;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    align-content: center; }
    .section-button p {
      font-size: 1.7rem;
      width: 70%;
      line-height: 100px;
      margin: 0;
      color: #ffffff;
      font-weight: 600;
      position: relative;
      display: inline-block; }
    .section-button .side-img {
      width: 40px;
      height: auto; }
      .section-button .side-img.arrow {
        position: absolute;
        top: 35px;
        right: 10px; }
    .section-button .img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 25px;
      left: 30px; } }

@media screen and (max-width: 400px) {
  .section-button {
    margin: 0;
    height: 100px;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    align-content: center; }
    .section-button p {
      font-size: 1.5rem;
      width: 70%;
      line-height: 100px;
      margin: 0;
      color: #ffffff;
      font-weight: 600;
      position: relative;
      display: inline-block; }
    .section-button .side-img {
      width: 40px;
      height: auto; }
      .section-button .side-img.arrow {
        position: absolute;
        top: 35px;
        right: 10px; }
    .section-button .img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 25px;
      left: 15px; } }

.pointer-link {
  cursor: pointer; }

.sidebar {
  margin: 0 0 2rem;
  border-left: 1px solid #929496; }
  .sidebar.floating-sidebar {
    padding: 1rem 2rem !important; }
  .sidebar .sidebar-title {
    border-bottom: 2px solid #59ac54; }
    .sidebar .sidebar-title h1 {
      margin: 0;
      font-weight: 400;
      font-size: 1.5rem; }
  .sidebar .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0; }
    .sidebar .side-item a {
      margin-left: auto; }
    .sidebar .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
  .sidebar .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.15rem;
    color: #252324; }
  .sidebar .side-img {
    width: 45px;
    height: auto; }
    .sidebar .side-img.arrow {
      margin-left: auto; }
  .sidebar .tracking-input {
    font-size: 1rem;
    width: 100%;
    min-height: 2rem; }
  .sidebar .dropcl {
    cursor: pointer; }
  .sidebar img.image-banner {
    width: 100%;
    margin-top: 20px; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .sidebar .side-title {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.1rem;
    width: 115px; } }

.tabbar-container {
  margin-top: 50px;
  width: 100%;
  border-bottom: 1px solid #c2c2c2; }
  .tabbar-container .tabbar-option {
    display: inline-block;
    position: relative;
    top: 1px;
    font-size: 1.2rem;
    border: 1px solid #c2c2c2;
    background: #c2c2c2;
    border-radius: 5px 5px 0 0;
    padding: 5px 30px;
    cursor: pointer;
    margin-right: 5px; }
    .tabbar-container .tabbar-option.active {
      border-bottom: 1px solid #59ac54;
      background: #6db568 !important; }

.service-item {
  margin: 4rem 0; }
  .service-item .item-title {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #59ac54;
    letter-spacing: 0.1rem; }
  .service-item .item-details {
    margin: 0;
    color: #252324;
    font-size: 1.2rem;
    line-height: 2rem; }

@media screen and (max-width: 900px) {
  .service-item {
    margin: 2rem 0; } }

.spinner {
  margin-left: calc(50% - 10px);
  margin-top: 18% !important; }

.fundacion-quote {
  margin: 4rem 0; }
  .fundacion-quote .quote-title {
    margin: 0;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #252324;
    font-weight: 400; }
  .fundacion-quote .quote-description {
    margin: 0;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #252324; }
  .fundacion-quote .quote-sign {
    margin: 2rem 0;
    width: 400px;
    height: 150px;
    margin-left: auto; }
    .fundacion-quote .quote-sign img {
      width: 100%; }
  .fundacion-quote .quote-owner {
    text-align: right;
    font-weight: bold; }

.news-item {
  display: flex;
  margin-bottom: 20px; }
  .news-item.bordered {
    border-top: 1px solid #c2c2c2;
    padding-top: 30px; }
  .news-item .media-container {
    flex: 1 1;
    cursor: pointer;
    position: relative; }
    .news-item .media-container img {
      width: 100%; }
    .news-item .media-container .play-button {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .news-item .media-container .play-button img {
        width: 80px; }
  .news-item .text-container {
    flex: 4 1;
    padding: 0 20px; }
    .news-item .text-container h2 {
      color: #59ac54;
      font-size: 1.4rem;
      margin: 10px 0 0; }
    .news-item .text-container .news-text {
      font-size: 1rem; }

@media screen and (max-width: 1300px) {
  .news-item .media-container {
    flex: 2 1; }
    .news-item .media-container .play-button img {
      width: 40px; } }

@media screen and (max-width: 900px) {
  .fundacion-quote {
    margin: 2rem 0; }
    .fundacion-quote .quote-title {
      margin: 0;
      font-size: 1.8rem;
      line-height: 2rem;
      color: #252324;
      font-weight: 400; }
    .fundacion-quote .quote-description {
      margin: 0;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #252324; }
    .fundacion-quote .quote-sign {
      margin: 2rem 0 30px 0;
      width: 300px;
      height: 120px;
      margin-left: auto; }
      .fundacion-quote .quote-sign img {
        width: 100%; }
  .news-item {
    flex-direction: column; }
    .news-item .media-container .play-button img {
      width: 30%; } }

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #86b127;
  width: 100%;
  height: 14rem; }
  .banner p {
    margin: 0;
    color: white; }

.banner .info-title {
  color: white;
  font-weight: bolder;
  font-size: 3.5rem !important; }

.banner-button {
  background: transparent;
  color: white;
  font-size: 1rem;
  border: 2px solid white;
  border-radius: 18px;
  margin-top: 20px;
  width: 200px;
  text-align: center;
  display: table-cell; }

.banner-responsive {
  display: none; }

.banner .banner-button a:link,
.banner .banner-button a:visited,
.banner .banner-button a:hover {
  text-decoration: none !important;
  color: white !important; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .banner p {
    padding: 10px 30px;
    line-height: 2rem;
    font-size: 2rem;
    text-align: center; } }

@media screen and (max-width: 900px) {
  .banner-responsive {
    display: inherit !important; }
  .banner .info-title {
    font-size: 1.9rem !important;
    padding: 20px;
    line-height: 2rem;
    text-align: center; }
  .banner .row {
    margin-bottom: 10px;
    text-align: center; }
  .banner {
    height: 100%;
    letter-spacing: normal; }
  .banner p {
    font-size: 11px;
    margin-top: -3em;
    font-weight: 800; }
  .banner .row-button {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center; }
  .banner .banner-button {
    font-size: 0.9em;
    margin: 1em;
    width: 15em !important;
    border-radius: 6px; }
  .banner .banner-button a:link,
  .banner .banner-button a:visited,
  .banner .banner-button a:hover {
    text-decoration: none !important;
    color: white !important; } }

button {
  margin: 0 10px 10px 0;
  padding: 0 2rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  width: auto;
  white-space: nowrap;
  border-radius: 35px;
  border: solid; }

.primary-button {
  background: #a8c94b !important;
  color: white; }

.secondary-button {
  background: transparent;
  color: white;
  border-color: #a8c94b; }

.sm {
  font-size: 1rem; }

.xmd {
  font-size: 1.5rem; }

.md {
  font-size: 2rem; }

.lg {
  font-size: 3rem; }

.carousel.carousel-slider {
  height: calc(100vh - 390px);
  overflow: unset !important; }
  .carousel.carousel-slider .slider-wrapper {
    height: 100%; }
    .carousel.carousel-slider .slider-wrapper ul {
      height: 100%; }

.carousel .control-dots {
  bottom: -2em !important; }

.carousel .control-dots .dot {
  background: #86b128; }

.button {
  border: #86b128 !important; }

.button-carousel {
  border: #86b128 !important; }

ul.control-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; }

.slide-div-container {
  height: 100%; }
  .slide-div-container img {
    height: 100%; }

.legend {
  display: flex;
  vertical-align: center;
  background: transparent !important;
  top: calc(10% - 55px);
  bottom: initial !important;
  left: 49% !important;
  opacity: 1 !important;
  width: 100% !important; }
  .legend .carousel-content {
    margin: 3rem 2rem 3rem 21rem;
    text-align: left; }
    .legend .carousel-content button {
      padding: 0 1rem;
      font-size: 1.5rem;
      background-color: #86b128 !important; }
  .legend .carousel-title {
    margin: 1rem 0;
    font-size: 6rem;
    line-height: 4.5rem;
    font-weight: 700;
    top: calc(25% - 35px); }
  .legend .carousel-info {
    margin: 0.5rem 0;
    font-size: 2rem;
    line-height: 2rem; }

.sidebar-carousel {
  z-index: 9999;
  position: absolute;
  background-color: white;
  width: 15%;
  max-width: 20rem;
  min-width: 17rem;
  top: calc(25% - 35px);
  left: 6%;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; }
  .sidebar-carousel .sidebar-title {
    color: white;
    background-color: #86b128 !important;
    border-bottom: 2px solid #86b128;
    border-radius: 5px 5px 0px 0px; }
    .sidebar-carousel .sidebar-title h1 {
      margin: 0;
      font-weight: 400;
      font-size: 2rem;
      text-align: center; }
  .sidebar-carousel .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    position: relative; }
    .sidebar-carousel .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .sidebar-carousel .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .sidebar-carousel .side-item form {
      width: 100%; }
      .sidebar-carousel .side-item form input {
        width: 100%; }
  .sidebar-carousel .side-title {
    margin-right: 3rem;
    margin-left: 1rem;
    font-size: 1.1rem;
    color: #252324; }
  .sidebar-carousel .side-img {
    width: 50px;
    height: auto; }
    .sidebar-carousel .side-img.arrow {
      margin-left: auto; }
  .sidebar-carousel .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    border-radius: 5px;
    padding-left: 1rem;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .legend {
    top: calc(10% - 55px);
    left: 46% !important;
    width: 100% !important; }
    .legend .carousel-content {
      margin: 2rem 1rem 2rem 19rem;
      text-align: left; }
      .legend .carousel-content button {
        padding: 0 1rem;
        font-size: 1.2rem; }
    .legend .carousel-title {
      margin: 1rem 0;
      font-size: 4rem;
      line-height: 3rem;
      font-weight: 600;
      top: calc(25% - 35px); }
    .legend .carousel-info {
      margin: 0.5rem 0;
      font-size: 1.5rem;
      line-height: 1.5rem; }
  .sidebar-carousel {
    width: 16rem;
    left: 4%; }
    .sidebar-carousel .sidebar-title h1 {
      font-size: 1.5rem; }
    .sidebar-carousel .side-item {
      padding: 0.5rem; }
    .sidebar-carousel .side-title {
      font-size: 1rem;
      line-height: 1.2rem; }
    .sidebar-carousel .side-img {
      width: 30px; } }

div.carousel-info .carousel.carousel-slider {
  height: 500px !important; }

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent !important;
  bottom: 60% !important;
  left: 50% !important;
  opacity: 1 !important;
  z-index: 20; }
  .info .carousel-info-title {
    margin: 0;
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 700; }
  .info .carousel-info-details {
    margin: 0;
    text-align: left; }
    .info .carousel-info-details p {
      font-size: 2.4rem;
      line-height: 2rem;
      padding: 2px;
      margin: 10px 0; }
  .info button {
    margin-top: 50px;
    background: transparent !important;
    font-size: 1.5rem; }

.slider-info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3); }

.item-summary {
  margin-top: 1rem; }
  .item-summary .icon-image {
    height: 7rem; }
  .item-summary .summary-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1e8d34;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0;
    text-align: center; }

.likes__list {
  position: absolute;
  box-sizing: border-box;
  max-width: 30%;
  z-index: 999;
  background: #1e8d34;
  padding: 5px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: snow; }

.summary-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  height: auto;
  padding: 0 14rem; }
  .summary-bar a {
    margin: 2rem 0;
    width: 20%;
    text-align: center;
    text-decoration: none;
    border-left: 2px solid #929496; }
    .summary-bar a:last-child {
      border-right: 2px solid #929496; }

a {
  text-decoration: none; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary-bar {
    padding: 0 3rem; } }

.item {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem; }
  .item .item-title {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2rem;
    color: #86b128;
    text-transform: uppercase; }
  .item p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #252324;
    text-align: center;
    margin: 0 0 1rem 0; }
  .item .link {
    color: #86b128;
    font-size: 1.3rem; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .item .item-title {
    font-size: 1.4rem; }
  .item p {
    font-size: 1.1rem;
    line-height: 1.4rem; }
  .item .link {
    color: #86b128;
    font-size: 1rem; } }

.summary {
  padding: 1rem 5rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 3em; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary {
    padding: 1rem 3rem 2rem; } }

.summary-products {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  min-height: 15rem; }
  .summary-products .product-detail {
    position: relative;
    text-transform: uppercase;
    width: 25%; }
    .summary-products .product-detail img {
      width: 100%; }
    .summary-products .product-detail::after {
      display: block;
      position: relative;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
      margin-top: -200px;
      height: 200px;
      width: 100%;
      content: ""; }
    .summary-products .product-detail .product-detail-info {
      z-index: 10;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      height: 100px; }
      .summary-products .product-detail .product-detail-info h2 {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: white;
        text-align: center; }
      .summary-products .product-detail .product-detail-info .product-detail-subtext {
        color: #a8c94b; }
    .summary-products .product-detail h3 {
      position: absolute;
      top: 200px;
      left: 0;
      width: 100%;
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      color: white;
      text-align: center; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary-products .product-detail .product-detail-info {
    height: 80px; }
    .summary-products .product-detail .product-detail-info h2 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 1px; } }

.slider-content {
  margin-top: -15px; }
  .slider-content .carousel.carousel-slider {
    height: auto; }

.home-agencies {
  position: relative; }
  .home-agencies .map-container {
    height: 400px; }
  .home-agencies .agencies-form {
    background: white;
    position: absolute;
    top: 60px;
    left: 60px;
    width: 600px;
    z-index: 100; }
    .home-agencies .agencies-form .agencies-title {
      padding: 10px 0 0 20px;
      border-bottom: 1px solid #c2c2c2; }
      .home-agencies .agencies-form .agencies-title h2 {
        margin: 0; }
    .home-agencies .agencies-form .agencies-form-title {
      padding: 10px 20px 0;
      font-size: 0.8rem; }
    .home-agencies .agencies-form .agencies-form-input {
      padding: 10px 20px; }
      .home-agencies .agencies-form .agencies-form-input .geo-input input {
        font-size: 1.1rem;
        width: 100%; }
      .home-agencies .agencies-form .agencies-form-input .label-div {
        color: #59ac54;
        font-size: 0.8rem; }
    .home-agencies .agencies-form .geo-button {
      padding: 0 20px 0;
      font-size: 0.8rem;
      cursor: pointer; }
      .home-agencies .agencies-form .geo-button img {
        width: 20px; }
    .home-agencies .agencies-form .address-button-container {
      padding: 0 20px 10px;
      text-align: right; }
      .home-agencies .agencies-form .address-button-container button {
        font-size: 0.8rem; }

.info-box {
  padding: 5px 10px;
  background: #fff;
  width: 200px !important; }
  .info-box h2 {
    color: #59ac54; }
  .info-box p {
    margin: 5px 0; }
    .info-box p.mb-2 {
      margin-bottom: 10px; }

@media screen and (max-width: 1300px) {
  .home-agencies .agencies-form {
    top: 60px;
    left: 20px;
    width: 400px; } }

.geosuggest__suggests--hidden {
  display: none; }

.geosuggest {
  position: relative; }
  .geosuggest .geosuggest__suggests-wrapper {
    z-index: 200;
    position: absolute;
    background: white;
    width: 100%; }
    .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
      border: 1px solid #c2c2c2;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0; }
      .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
        padding: 3px 10px;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer; }
        .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover {
          background: #f1f1f2; }

.home-map-pickup {
  position: relative; }
  .home-map-pickup .map-container {
    min-height: 400px;
    width: 100%; }

.home-map-pickup table {
  color: #86b128;
  padding: 10%;
  margin-top: 1em;
  margin-left: 0.5em;
  margin-right: 2em; }

.div-container-table {
  padding-right: 0px; }

.container-banner {
  width: 100%; }

.container-banner table thead tr th {
  text-align: center;
  color: #86b128;
  font-weight: 700;
  font-size: 0.9em;
  background-color: transparent !important;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-banner table thead {
  border-right-width: 10px; }

.container-banner thead {
  background-color: transparent !important;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-table {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 430px; }

.container-banner table tbody tr td {
  font-size: 0.7em !important;
  padding: 0.5em;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-banner .special-border {
  border-bottom: 0px !important; }

.container-banner .special-border1 {
  border-right: 0px !important; }

.container-banner .special-border2 {
  border-bottom: 0px !important;
  border-right: 0px !important; }

.th-left {
  text-align: left !important;
  padding: 0.5em; }

.avisos {
  color: #86b128 !important;
  font-size: 0.8em;
  font-weight: bolder;
  margin-top: 3%;
  margin-left: 1em; }

.info-box {
  padding: 5px 10px;
  background: #fff;
  width: 200px !important; }
  .info-box h2 {
    color: #59ac54; }
  .info-box p {
    margin: 5px 0; }
    .info-box p.mb-2 {
      margin-bottom: 10px; }

@media screen and (max-width: 1300px) {
  .home-map-pickup .agencies-form {
    top: 60px;
    left: 20px;
    width: 400px; } }

.geosuggest__suggests--hidden {
  display: none; }

.geosuggest {
  position: relative; }
  .geosuggest .geosuggest__suggests-wrapper {
    z-index: 200;
    position: absolute;
    background: white;
    width: 100%; }
    .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
      border: 1px solid #c2c2c2;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0; }
      .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
        padding: 3px 10px;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer; }
        .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover {
          background: #f1f1f2; }

@media screen and (max-width: 900px) {
  .container-banner .container-table {
    height: 270px;
    padding-right: 1.5em; }
  .avisos {
    font-size: 0.7em; } }

.image-text-section {
  position: relative; }
  .image-text-section .carousel-title {
    font-size: 1.8rem;
    position: absolute;
    top: 25%;
    margin: 0 0 0 20px;
    padding-left: 30px;
    color: white;
    line-height: 2rem;
    font-weight: 700;
    width: 100%; }
  .image-text-section .legend .sidebar {
    margin: 0;
    width: 100%; }
  .image-text-section .img {
    width: 100%;
    height: auto; }
  .image-text-section .legend.info .carousel-info-title {
    font-size: 2rem;
    line-height: 2rem; }
  .image-text-section .legend.info .carousel-info-details p {
    font-size: 1.1rem;
    line-height: 0.8rem; }
  .image-text-section .legend.info button {
    margin: 0 !important;
    font-size: 1.1rem; }

@media screen and (max-width: 600px) {
  .image-text-section {
    position: relative; }
    .image-text-section .img {
      height: 300px;
      object-position: center;
      object-fit: cover; }
    .image-text-section .carousel-title {
      font-size: 1.4rem;
      position: absolute;
      top: 0;
      margin: 0; }
      .image-text-section .carousel-title .legend.info .carousel-info-title {
        font-size: 2rem;
        line-height: 2.2rem; }
      .image-text-section .carousel-title .legend.info .carousel-info-details p {
        font-size: 1rem;
        line-height: 0.8rem; }
      .image-text-section .carousel-title .legend.info button {
        margin: 0 !important;
        font-size: 0.8rem; } }

.quick-access-section {
  background-color: white;
  position: relative;
  width: 100%;
  padding-bottom: 20px; }
  .quick-access-section .sidebar-title {
    color: #252324;
    border-bottom: 2px solid #59ac54;
    margin: 0 20px 0 20px; }
    .quick-access-section .sidebar-title h1 {
      margin: 0 0 10px 20px;
      font-weight: 400;
      font-size: 2rem;
      text-align: left; }
  .quick-access-section .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 20px 0 20px;
    position: relative; }
    .quick-access-section .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .quick-access-section .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .quick-access-section .side-item form {
      width: 100%; }
      .quick-access-section .side-item form input {
        width: 100%; }
  .quick-access-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.3rem;
    color: #929496;
    font-weight: 600; }
  .quick-access-section .side-img {
    width: 40px;
    height: auto; }
    .quick-access-section .side-img.arrow {
      margin-left: auto; }
  .quick-access-section .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    border: 1px solid rgba(146, 148, 150, 0.3);
    padding-left: 1rem; }

.services-section {
  background-color: white;
  position: relative;
  width: 100%;
  padding-bottom: 20px; }
  .services-section .sidebar-title {
    color: #252324;
    border-bottom: 2px solid #59ac54;
    margin: 0 20px 0 20px; }
    .services-section .sidebar-title h1 {
      margin: 0 0 10px 20px;
      font-weight: 400;
      font-size: 2rem;
      text-align: left; }
  .services-section .side-item {
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 20px 0 20px;
    flex-direction: column;
    position: relative; }
    .services-section .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .services-section .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .services-section .side-item form {
      width: 100%; }
      .services-section .side-item form input {
        width: 100%; }
  .services-section .side-title {
    margin-right: 0;
    margin-left: 0;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 0;
    margin-left: 0;
    font-size: 1.5rem;
    color: #59ac54; }
  .services-section .side-img {
    width: 40px;
    height: auto; }
    .services-section .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; }
  .services-section .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    margin-bottom: 10px;
    border: 1px solid rgba(146, 148, 150, 0.3); }

@media screen and (min-width: 460px) and (max-width: 520px) {
  .services-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    color: #59ac54; }
  .side-img {
    width: 30px;
    height: auto; }
    .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; } }

@media screen and (max-width: 460px) {
  .services-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    line-height: 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    color: #59ac54; }
  .side-img {
    width: 30px;
    height: auto; }
    .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; } }

.map-container {
  background: #383e43; }
  .map-container img {
    width: 100%; }

.go-down {
  top: 60vh;
  position: absolute;
  text-align: center;
  -webkit-animation: bounce infinite 2s;
          animation: bounce infinite 2s;
  left: calc(50% - 25px);
  cursor: pointer; }

@-webkit-keyframes bounce {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  /*60% {transform: translateY(15px);}*/ }

@keyframes bounce {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  /*60% {transform: translateY(15px);}*/ }

.image-text-section {
  position: relative; }
  .image-text-section .carousel-title {
    font-size: 1.8rem;
    position: absolute;
    top: 25%;
    margin: 0 0 0 20px;
    padding-left: 30px;
    color: white;
    line-height: 2rem;
    font-weight: 700;
    width: 100%; }
  .image-text-section .legend .sidebar {
    margin: 0;
    width: 100%; }
  .image-text-section .img {
    width: 100%;
    height: auto; }
  .image-text-section .legend.info .carousel-info-title {
    font-size: 2rem;
    line-height: 2rem; }
  .image-text-section .legend.info .carousel-info-details p {
    font-size: 1.1rem;
    line-height: 0.8rem; }
  .image-text-section .legend.info button {
    margin: 0 !important;
    font-size: 1.1rem; }

@media screen and (max-width: 600px) {
  .image-text-section {
    position: relative; }
    .image-text-section .img {
      height: 175px;
      object-position: center;
      object-fit: cover; }
    .image-text-section .carousel-title {
      font-size: 1.4rem;
      position: absolute;
      top: 0;
      margin: 0; }
      .image-text-section .carousel-title .legend.info .carousel-info-title {
        font-size: 2rem;
        line-height: 2.2rem; }
      .image-text-section .carousel-title .legend.info .carousel-info-details p {
        font-size: 1rem;
        line-height: 0.8rem; }
      .image-text-section .carousel-title .legend.info .button-carousel {
        margin: 0 !important;
        margin-top: 1em;
        font-size: 0.8rem;
        background-color: green !important;
        border-color: green !important;
        font-weight: 800; } }

.menu .menu-children {
  display: flex;
  align-items: center; }

.menu .icon span.material-icons {
  padding: 0;
  font-size: 14px; }

.menu .red-text .menu-item {
  color: #c22c2b; }

.underline {
  border-bottom: 2px solid rgba(169, 173, 167, 0.3); }

div.menu-item {
  display: flex;
  align-items: center; }
  div.menu-item span {
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    padding: 0.3rem 1rem; }
    div.menu-item span.menu-item {
      font-size: 1.1rem;
      font-weight: 600;
      color: #929496;
      text-transform: uppercase; }
    div.menu-item span.menu-item-lower {
      font-size: 1.1rem;
      font-weight: 600;
      color: #929496; }
    div.menu-item span.secondary-menu-item {
      font-size: 1.2rem;
      color: #59ac54;
      text-decoration: none; }

.content-products h1 {
  margin: 0; }

.content-products .image-contents {
  min-width: 200px;
  max-width: 270px;
  padding: 8px 14px;
  text-align: center; }
  .content-products .image-contents .icon-img {
    width: 100%; }
    .content-products .image-contents .icon-img.second-img {
      margin-top: 50px;
      width: 150px; }

.content-products .product-title {
  display: flex;
  align-items: center; }
  .content-products .product-title .product-title-primary {
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: #59ac54; }
  .content-products .product-title .product-title-secondary {
    font-size: 1.4em;
    line-height: 1.8rem;
    font-weight: 400;
    color: #a8c94b;
    margin: 0 0.5rem; }

.content-products .product-detail p {
  font-size: 1.2rem;
  line-height: 1.8rem; }

.content-products .product-extra-info {
  margin: 2rem 0;
  text-transform: uppercase; }
  .content-products .product-extra-info p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324; }
    .content-products .product-extra-info p span {
      padding: 0 0.5rem 0 0; }
    .content-products .product-extra-info p .primary {
      font-weight: 700;
      color: #59ac54; }
    .content-products .product-extra-info p .secondary {
      font-weight: 400;
      color: #a8c94b; }

.content-products .content-products-picture {
  margin: 0 3rem;
  width: 400px;
  height: 100px;
  border: 2px solid darkgreen; }

.content-products .extra-link {
  font-size: 2rem;
  color: #59ac54; }

.screen-contents {
  margin-bottom: 10rem; }
  .screen-contents .background img {
    width: 100%; }
  .screen-contents .details-container {
    display: flex;
    justify-content: space-around; }
    .screen-contents .details-container .content {
      padding: 1rem 5rem;
      width: 100%;
      flex: 4 1; }
      .screen-contents .details-container .content .content-title {
        border-bottom: 2px solid #59ac54; }
        .screen-contents .details-container .content .content-title h1 {
          margin: 0 0 1rem;
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.3rem; }
      .screen-contents .details-container .content .content-description {
        margin: 3rem 0; }
        .screen-contents .details-container .content .content-description p {
          margin: 0;
          font-size: 1.2rem;
          color: #4d4d4f; }
    .screen-contents .details-container .sidebar {
      padding: 1rem 5rem;
      flex: 1 1; }

.banner-text {
  font-size: 2rem;
  color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents.products-screen {
    margin: 0; }
    .screen-contents.products-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.products-screen .details-container .content-products .content-products-info .product-title {
        align-items: flex-start;
        flex-direction: column; }
        .screen-contents.products-screen .details-container .content-products .content-products-info .product-title .product-slash {
          display: none; }
        .screen-contents.products-screen .details-container .content-products .content-products-info .product-title .product-title-secondary {
          margin: 0; }
      .screen-contents.products-screen .details-container .content-products .image-contents {
        display: none; } }

.screen-contents.services-screen .content-title {
  position: relative; }

.screen-contents.services-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .screen-contents.services-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .screen-contents.services-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

/* Price Table */
.ContainerTable {
  display: flex;
  flex-direction: row;
  width: 450px;
  height: 400px;
  border: 1px solid black; }

.LeftContainer {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  border-right: 1px solid black; }

.M1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
  height: 30%;
  color: white;
  border-bottom: 1px solid black;
  background-color: #59ac54; }

.M2 {
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 600;
  color: #343a40;
  margin-top: 10px;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid black; }

.M3 {
  display: flex;
  text-align: center;
  align-items: center;
  color: #343a40;
  font-weight: 600;
  width: 100%;
  height: 50%; }

.RightContainer {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%; }

.RateField {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 18%;
  color: white;
  font-weight: 600;
  background-color: #59ac54;
  border-bottom: 1px solid black; }

.FromTo {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  width: 100%;
  height: 11.5%; }

.From {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: white;
  font-weight: 600;
  background-color: #59ac54;
  border-right: 1px solid black; }

.To {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: white;
  font-weight: 600;
  background-color: #59ac54; }

.Price {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 21.9%;
  border-bottom: 1px solid black; }

.PriceOne {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-right: 1px solid black; }

.PriceTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%; }

.Percent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48.8%; }

.PercentOne {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-right: 1px solid black; }

.PercentTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%; }

@media screen and (max-width: 900px) {
  .screen-contents.services-screen {
    margin: 0; }
    .screen-contents.services-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.services-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.services-screen .phone a {
    display: block; }
  .screen-contents.services-screen .phone .hide-on-xs {
    display: none; }
  .ContainerTable {
    width: 95%; } }

.steps-for-tips {
  margin-top: 50px;
  padding: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center; }
  .steps-for-tips .step-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem; }
    .steps-for-tips .step-item .step-number {
      font-size: 2rem;
      line-height: 1rem;
      color: #a8c94b;
      align-self: flex-start;
      font-weight: 400;
      margin: 0; }
    .steps-for-tips .step-item .step-icon .step-img {
      height: 10rem; }
    .steps-for-tips .step-item .step-description {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #252324; }

.tips-suggestion h6 {
  color: #59ac54;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400; }

.separator-suggestion {
  border: 1px solid #929496;
  margin: 2rem 0; }

.pack-suggestion {
  margin: 2rem 0; }
  .pack-suggestion .pack-title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #252324;
    font-weight: 600;
    margin: 0; }
  .pack-suggestion .pack-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324;
    font-weight: 400; }

.embalaje-suggestion {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #252324;
  margin: 1rem 0; }
  .embalaje-suggestion .embalaje-suggestion-title {
    font-weight: normal;
    margin: 0; }
  .embalaje-suggestion .embalaje-suggestion-description {
    margin: 0; }

select {
  min-height: 1rem;
  min-width: 20rem;
  color: #252324;
  font-size: 1rem;
  font-weight: 400; }

@media screen and (max-width: 900px) {
  .screen-contents.tips-screen {
    margin: 0; }
    .screen-contents.tips-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.tips-screen .details-container .steps-for-tips {
        flex-direction: column;
        padding: 0 0 2rem; }
        .screen-contents.tips-screen .details-container .steps-for-tips .step-item {
          margin: 1rem; }
          .screen-contents.tips-screen .details-container .steps-for-tips .step-item img {
            height: 7rem; } }

.match-detail .match-title {
  cursor: pointer;
  text-transform: uppercase;
  color: #59ac54;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative; }
  .match-detail .match-title i {
    position: absolute;
    font-size: 30px;
    margin-top: 4px;
    margin-left: 10px; }

.content-search-bar {
  margin: 2rem 0;
  padding: 0rem;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  .content-search-bar .search-bar-title {
    color: #59ac54;
    font-size: 1.2rem;
    font-weight: 400; }
  .content-search-bar input {
    display: inline-block;
    width: 25rem;
    height: 2.5rem;
    font-size: 1.2rem;
    padding: 0 0.8rem;
    box-shadow: none;
    border-width: 1; }
  .content-search-bar .button-container {
    width: 200px;
    text-align: center; }
    .content-search-bar .button-container .sk-spinner {
      display: inline-block;
      padding-top: 5px;
      background: red; }
    .content-search-bar .button-container button {
      font-size: 1.2rem; }

.divisor {
  margin: 4rem 0; }

.help-info {
  font-size: 1.1rem; }
  .help-info .link {
    font-size: 1.2rem;
    display: inline-block;
    color: #59ac54; }
  .help-info .img-container {
    text-align: center;
    padding: 5rem; }
    .help-info .img-container img {
      width: 100%; }

.detail-info .title {
  font-size: 1.4rem;
  color: #59ac54; }

.detail-info .deliver {
  display: flex;
  margin-bottom: 20px; }
  .detail-info .deliver .deliver-summary {
    flex: 5 1;
    border-right: 1px solid #4d4d4f; }
    .detail-info .deliver .deliver-summary .point {
      margin-top: 30px; }
      .detail-info .deliver .deliver-summary .point:first-child {
        margin-top: 0px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-title {
      font-size: 1.2rem;
      font-weight: normal !important;
      color: #4d4d4f !important; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-description {
      font-size: 1rem;
      color: #4d4d4f !important; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-content {
      min-height: 80px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-icon img {
      width: 30px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-tail::after {
      background: transparent !important;
      border: 1px dashed #59ac54; }
  .detail-info .deliver .deliver-metadata {
    flex: 2 1;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 10px; }
    .detail-info .deliver .deliver-metadata .header-metadata {
      margin: 20px 0 10px;
      font-size: 1.2rem;
      line-height: 1.1rem; }
      .detail-info .deliver .deliver-metadata .header-metadata:first-child {
        margin: 5px 0 10px; }

table {
  border: 0;
  margin: 0;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  border-collapse: collapse; }
  table tr {
    border-width: 0; }
    table tr th {
      text-align: left;
      background-color: #f1f1f2;
      font-weight: 400;
      border-width: 0; }
    table tr td {
      text-align: left; }

.aditional-info {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem; }

.incident-title {
  color: #59ac54; }

.delivered {
  padding-left: 8px;
  color: #59ac54;
  font-size: 1.4rem; }
  .delivered img {
    width: 14px;
    margin-right: 10px; }

@media screen and (max-width: 900px) {
  .screen-contents.tracking-screen {
    margin: 0; }
    .screen-contents.tracking-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
  .screen-contents .details-container .sidebar {
    display: none; }
  .screen-contents .details-container .content {
    padding: 0; }
  .content-search-bar {
    display: inherit;
    padding: 0px 0px 15px 10px;
    background-color: #ffffff; }
    .content-search-bar input {
      display: inline-block;
      width: 90%;
      height: 2.5rem;
      font-size: 1.2rem;
      margin: -10px 0 15px 0;
      box-shadow: none;
      border-width: 1; }
  .img-container {
    padding: 1rem 0 !important; } }

@media screen and (max-width: 550px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }

@media screen and (max-width: 470px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }

@media screen and (max-width: 400px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.7rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }

.packs-content h1 {
  color: #252324;
  font-size: 2rem;
  font-weight: 400; }

.packs-content p {
  color: #252324;
  font-size: 1.3rem; }

.button {
  border: #86b128 !important; }

.products-container .content-products h1 {
  margin: 0; }

.products-container .content-products .image-contents {
  min-width: 200px;
  max-width: 270px;
  text-align: center;
  padding: 0 20px 50px; }
  .products-container .content-products .image-contents .product-separator {
    position: relative;
    width: 100%;
    padding: 0 50px;
    margin-bottom: 10px;
    margin-top: -10px; }
    .products-container .content-products .image-contents .product-separator div {
      height: 1px;
      background: #c2c2c2; }

.products-container .content-products .inner-content-products {
  margin-left: 2em; }

.products-container .content-products .product-title {
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  .products-container .content-products .product-title .product-title-primary {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #59ac54; }
  .products-container .content-products .product-title .product-title-secondary {
    font-size: 1.4em;
    font-weight: 400;
    color: #a8c94b;
    margin: 0 0.5rem; }

.products-container .content-products .product-detail.margined {
  margin-top: -1rem; }

.products-container .content-products .product-detail p {
  margin: 1rem 0;
  font-size: 1.2rem;
  line-height: 1.8rem; }

.products-container .content-products .product-extra-info {
  margin: 2rem 0; }
  .products-container .content-products .product-extra-info p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324; }
    .products-container .content-products .product-extra-info p span {
      padding: 0 0.5rem 0 0; }
    .products-container .content-products .product-extra-info p .primary {
      font-weight: 700;
      color: #59ac54;
      text-transform: uppercase; }
    .products-container .content-products .product-extra-info p .secondary {
      font-weight: 400;
      color: #a8c94b;
      text-transform: uppercase; }
  .products-container .content-products .product-extra-info .details {
    text-transform: none; }

.products-container .content-products .content-products-picture {
  margin: 0 3rem;
  width: 400px;
  height: 100px;
  border: 2px solid darkgreen; }

.products-container .content-products .extra-link {
  font-size: 2rem;
  color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents {
    margin: 2rem 2rem 10rem 2rem; }
  .products-container {
    margin-top: 0; }
    .products-container .content-products {
      margin-top: 0;
      flex-direction: column; }
      .products-container .content-products .product-title {
        flex-direction: column;
        align-items: flex-start; }
        .products-container .content-products .product-title .product-slash {
          display: none; }
        .products-container .content-products .product-title .product-title-secondary {
          margin: 0; }
      .products-container .content-products .image-contents {
        min-width: 300px;
        padding: 20px;
        text-align: center;
        align-self: center; }
        .products-container .content-products .image-contents .product-separator {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          margin-top: 0px; }
          .products-container .content-products .image-contents .product-separator div {
            height: 1px;
            background: #c2c2c2; }
    .products-container .product-title {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .products-container .product-title .product-title-primary {
        font-size: 1rem;
        text-transform: uppercase;
        color: #59ac54; }
      .products-container .product-title .product-title-secondary {
        font-size: 0.8rem;
        font-weight: 400;
        color: #a8c94b;
        margin: 0 0.5rem; }
    .products-container .product-detail.margined {
      margin-top: -1rem; }
    .products-container .product-detail p {
      margin: 1rem 0;
      font-size: 1.2rem;
      line-height: 1.8rem; } }

.tabs {
  margin: 2rem 0; }

.product-considerations {
  margin-top: 3rem;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  padding: 1rem 0;
  display: flex;
  align-items: center; }
  .product-considerations .one-fourth {
    height: 150px;
    display: flex;
    border-right: 1px solid #c2c2c2;
    width: 25%;
    font-size: 1.2rem;
    line-height: 2rem;
    align-items: center; }
    .product-considerations .one-fourth .text-container {
      padding: 0 10px;
      text-align: center; }
    .product-considerations .one-fourth .image-container {
      width: 100%;
      text-align: center; }
      .product-considerations .one-fourth .image-container img {
        position: relative;
        height: 130px; }
  .product-considerations .one-half {
    display: inline-block;
    width: 50%;
    font-size: 1.4rem;
    line-height: 2.2rem; }
    .product-considerations .one-half p {
      margin-left: 20px; }

.products-container {
  margin-top: 50px; }

@media screen and (max-width: 900px) {
  .screen-contents.quote-screen {
    margin: 0; }
    .screen-contents.quote-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
  .screen-contents {
    margin: 2rem 2rem 10rem 2rem; }
  .product-considerations {
    flex-direction: column; }
    .product-considerations .one-fourth {
      height: 80px;
      display: flex;
      width: 100%;
      text-align: center;
      align-self: center;
      border-right: none;
      font-size: 1.2rem;
      line-height: 2rem;
      align-items: center; }
    .product-considerations .text-container {
      padding: 0 10px;
      text-align: center !important; }
    .product-considerations .one-half {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      font-size: 1.4rem;
      line-height: 2.2rem; }
      .product-considerations .one-half p {
        margin-left: 0px; } }

.terms-content p {
  padding: 2rem 0;
  font-size: 1.2rem;
  color: #252324;
  font-size: large;
  text-align: justify; }

@media screen and (max-width: 900px) {
  .screen-contents.terms-screen {
    margin: 0; }
    .screen-contents.terms-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.rezagos-content p {
  padding: 2rem 0;
  font-size: 1.2rem;
  color: #252324;
  font-size: large;
  text-align: justify; }

.content-title h1 {
  color: #468d52 !important; }

@media screen and (max-width: 900px) {
  .screen-contents.rezagos-screen {
    margin: 0; }
    .screen-contents.rezagos-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.screen-contents-access {
  background: url(/static/media/access.7c028c6c.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  .screen-contents-access .access-contents {
    margin: 10rem 0;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 50rem; }
    .screen-contents-access .access-contents .access-title {
      font-size: 1.5rem;
      color: #a8c94b;
      font-weight: 400;
      border-bottom: 2px solid;
      margin: 1rem 2rem; }
    .screen-contents-access .access-contents .access-body {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .screen-contents-access .access-contents .access-body .access-item {
        padding: 0 2rem;
        margin: 0.5rem 0;
        align-items: center;
        display: flex; }
        .screen-contents-access .access-contents .access-body .access-item .access-body-title {
          margin: 0;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: white; }
      .screen-contents-access .access-contents .access-body button {
        margin-left: auto;
        font-size: 1.5rem;
        background: transparent !important; }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .screen-contents-access .access-contents {
    margin: 10rem 10px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 30rem; } }

@media screen and (max-width: 600px) {
  .screen-contents-access .access-contents {
    margin: 10rem 10px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 20rem; }
    .screen-contents-access .access-contents .access-body {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .screen-contents-access .access-contents .access-body .access-item {
        padding: 0 2rem;
        margin: 0.5rem 0;
        align-items: center;
        display: flex; }
        .screen-contents-access .access-contents .access-body .access-item .access-body-title {
          margin: 0 10px;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: white; } }

.agencies-container .agencies-form {
  margin-top: 50px; }
  .agencies-container .agencies-form .agencies-form-title {
    font-size: 1.5rem;
    color: #929496; }
  .agencies-container .agencies-form .label-div {
    display: inline-block; }
  .agencies-container .agencies-form .size-input input {
    padding: 2px 5px;
    font-size: 1rem;
    height: 38px;
    line-height: 1rem;
    width: 100%;
    margin-bottom: 5px; }
  .agencies-container .agencies-form .geo-button div {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px; }
    .agencies-container .agencies-form .geo-button div img {
      height: 20px;
      position: absolute;
      top: 5px;
      left: 0; }
  .agencies-container .agencies-form .address-button-container {
    text-align: right; }
    .agencies-container .agencies-form .address-button-container button {
      font-size: 1.2rem; }

.agencies-container .map-container {
  margin-top: 50px;
  height: 600px; }
  .agencies-container .map-container .info-box {
    padding: 5px 10px;
    background: #fff;
    width: 200px !important; }
    .agencies-container .map-container .info-box p {
      margin: 5px 0; }
      .agencies-container .map-container .info-box p.mb-2 {
        margin-bottom: 10px; }

.agencia {
  width: 48%;
  float: left;
  min-height: 250px; }
  .agencia h3 {
    color: #333333;
    font-family: "Cairo", sans-serif;
    color: #59ac54; }
  .agencia .direccion {
    color: #727272;
    font-weight: bold;
    min-height: 60px;
    margin: 0; }
  .agencia .telefono {
    min-height: 60px;
    margin: 0; }
  .agencia span {
    color: #59ac54; }

.boton_agencias {
  border-color: #59ac54;
  box-shadow: 2px 2px 2px 1px #aaa; }

.boton_ver {
  border-color: #59ac54;
  border-radius: 0%; }

.boton_ver:hover {
  box-shadow: 2px 2px 2px 1px #aaa; }

.boton_agencias:focus {
  outline: none; }

.agencia:nth-child(2n+2) {
  float: right; }

.react-autosuggest__container {
  position: relative;
  display: inline-block;
  min-width: 72%; }

.react-autosuggest__input {
  width: 99%;
  height: 30px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input:focus {
  outline: none; }

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  width: 99%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd; }

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff; }

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat; }

.highlight {
  color: #59ac54;
  font-weight: bold; }

.react-autosuggest__suggestion--focused .highlight {
  color: #120000; }

@media screen and (max-width: 900px) {
  .screen-contents.agencies-screen {
    margin: 0; }
    .screen-contents.agencies-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.agencies-screen .details-container .agencies-form-title {
        font-size: 1.2rem;
        line-height: 1.5rem; }
  .react-autosuggest__container {
    display: block;
    min-width: 100%; } }

@media screen and (max-width: 900px) {
  .screen-contents.joinus-screen {
    margin: 0; }
    .screen-contents.joinus-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.screen-contents .details-container .content .content-title {
  border-bottom: 2px solid #59ac54;
  line-height: 4rem; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    border-bottom: 2px solid #59ac54;
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.iframe {
  border: 1px solid #f1f1f2;
  background: white;
  padding: 1rem; }

.form-container {
  font-size: 1rem;
  color: #4d4d4f;
  margin-top: 2rem; }
  .form-container input {
    font-size: 1rem;
    height: 2rem; }

.informationForm {
  font-size: 1.2rem;
  margin-top: 2rem; }

.inputForm {
  color: #252324;
  font-size: 1.5rem;
  font-weight: 400;
  height: 3rem; }

.messageForm {
  color: #252324;
  font-size: 1rem;
  font-weight: 400;
  height: 14rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem; }

.labelForm {
  height: auto;
  display: grid;
  margin-top: 0.6rem; }

.sendForm {
  display: flex;
  justify-content: flex-end; }
  .sendForm button {
    font-size: 1.2rem;
    width: 150px; }

@media screen and (max-width: 900px) {
  .informationForm {
    font-size: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem; }
  .sendForm {
    display: flex;
    justify-content: center; }
    .sendForm button {
      font-size: 1.2rem;
      width: 150px; } }

.contact-screen .content-title {
  position: relative; }

.contact-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .contact-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .contact-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

.titel-tex {
  color: #28a745; }

.tex-content p {
  font-size: 20px; }

.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 55px;
  background-color: #c9c9c9;
  border-radius: 15px 10px 0px 0px; }

.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px; }

.select {
  width: 100%; }

.formContainer input {
  padding-left: 5px; }

.field {
  display: flex;
  flex-direction: column;
  margin: 1px; }

.fieldNa {
  display: flex;
  flex-direction: row;
  width: 100%; }

.inp {
  width: 90%; }

.inp1 {
  width: 100%; }

.field1 {
  width: 100%; }

.textBox {
  display: flex;
  flex-direction: column;
  width: 100%; }

.textBox textarea {
  font-size: 15px;
  max-width: 100%;
  height: 150px;
  max-height: 150px;
  resize: none;
  padding-left: 5px; }

.fieldRequired {
  font-weight: 100;
  font-size: larger;
  font-style: normal; }

.send {
  display: flex;
  justify-content: flex-end;
  width: 100%; }

.bt {
  width: 19%;
  height: 30px;
  border: none;
  font-weight: 600;
  color: white;
  background: #28a745 !important;
  border-radius: 10px; }

.error {
  font-weight: 600;
  color: red; }

.iframe {
  border: 1px solid #f1f1f2;
  background: white;
  padding: 1rem; }

.contact-form-footer {
  margin-top: 30px;
  font-size: 1.2rem;
  line-height: 1.8rem; }
  .contact-form-footer a {
    color: #59ac54 !important;
    font-weight: bold; }
    .contact-form-footer a:hover {
      text-decoration: underline; }
  .contact-form-footer .form-footer-service {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.8rem; }
    .contact-form-footer .form-footer-service .footer-service-title {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #59ac54; }

@media screen and (max-width: 900px) {
  .bt {
    width: 20%; } }

@media screen and (max-width: 600px) {
  .bt {
    width: 28%; } }

@media screen and (max-width: 900px) {
  .screen-contents.contact-screen {
    margin: 0; }
    .screen-contents.contact-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.contact-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.contact-screen .phone a {
    display: block; }
  .screen-contents.contact-screen .phone .hide-on-xs {
    display: none; }
  .bt {
    width: 35%; } }

.error {
  display: block !important; }

.active {
  color: white !important;
  background-color: #6db568 !important; }

.btn {
  border: 1px solid #6db568 !important; }

.btn-primary {
  border: 1px solid #6db568 !important;
  background-color: #6db568 !important;
  color: white !important; }

.btn-secondary {
  border: 1px solid gray !important;
  background-color: gray !important;
  color: white !important; }

.card-header {
  background-color: #6db568 !important;
  color: white !important;
  cursor: pointer; }

.screen-contents .details-container .content .content-title {
  border-bottom: 2px solid #6db568;
  line-height: 4rem; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    border-bottom: 2px solid #6db568;
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.screen-contents .details-container .content .content-title {
  border-bottom: 2px solid #59ac54;
  line-height: 4rem; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    border-bottom: 2px solid #59ac54;
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.HeaderContent_jumbotron__34r6o{
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-image: url(/static/media/via-cargo-cotizador.215764c0.png);
  background-repeat: no-repeat;
  background-position: 65%;
}

@media (max-width: 600px) {
  .HeaderContent_jumbotron__34r6o {
    background-image: url(/static/media/fondo-mobile.a5c4ed0c.png);
    padding: 4rem 2rem;
  }
}

.HeaderContent_jumbotron-fluid__3Lc7v {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}


.HeaderContent_jumbotron2__1J8OQ {
  /*padding: 2rem 1rem;*/
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-image: url(/static/media/fondo-via-cargo.4e8e0e30.png);
  background-repeat: no-repeat;
  background-position: right 0%;
  background-size: 120%;
}
@media (max-width: 600px) {
.HeaderContent_jumbotron2__1J8OQ {
background-image: url(/static/media/fondo-mobile.a5c4ed0c.png);
  padding: 4rem 2rem;
  
}
}

.HeaderContent_row__1TSp8 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
margin-top: 40px;
}

.HeaderContent_card__A8siY {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.HeaderContent_cardBody__Sn4jh {
flex: 1 1 auto;
min-height: 1px;
padding: 1.25rem 0;
text-align: center;
min-height: 313px;
}

.HeaderContent_card-title__1jxjp img {
  margin: auto;
  display: table;
} 

.HeaderContent_cardText__AwfM4:last-child {
  margin-bottom: 0;
  font-size: 14px;
}

.HeaderContent_card2__mlluJ > .HeaderContent_list-group__BFY2T:last-child .HeaderContent_list-group-item__beZC6:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
  border: medium solid #FF0509;
}



.HeaderContent_cardTitle2__bdCZm {
  margin-bottom: 0;
  background-color: #9ec64f;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

button {
  margin: 0 10px 10px 0;
  padding: 0 2rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  width: auto;
  white-space: nowrap;
  border-radius: 35px;
  border: solid; }

.primary-button {
  background: black !important;
  color: white; }

.secondary-button {
  background: transparent;
  color: white;
  border-color: #a8c94b; }

.sm {
  font-size: 1rem; }

.xmd {
  font-size: 1.5rem; }

.md {
  font-size: 2rem; }

.lg {
  font-size: 3rem; }

.spinner {
  margin-left: calc(50% - 10px);
  margin-top: 18% !important; }

.content-header .content-title h1 {
  color: #1b802e;
  font-size: 2.1em;
  font-weight: bolder !important;
  margin: 0 0 2rem !important; }

.content-header .content-text {
  color: #1b802e;
  font-size: 1.2em;
  margin-top: -36px !important;
  margin-bottom: 16px; }

.container-table {
  overflow: scroll; }

.content-bread {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 7px 0 1px 19px;
  vertical-align: middle;
  color: grey;
  font-weight: bolder;
  margin-bottom: 5px;
  margin-top: 2em; }

.container-pickup table thead tr th {
  text-align: center;
  color: grey;
  font-weight: bolder;
  background-color: transparent !important; }

.th-left {
  text-align: left !important; }

.container-pickup .container-table {
  height: 400px;
  padding: 0.5em; }

.container-pickup table tbody tr td {
  font-size: 0.8em !important;
  padding: 0.5em;
  font-weight: bolder;
  color: grey; }

.container-pickup table tbody tr {
  border-bottom: 1px solid #efe9e9; }

.container-pickup .special-border1 {
  text-align: center; }

.container-pickup .special-border2 {
  text-align: center; }

.avisos {
  color: #1e7619;
  font-size: 0.8em;
  font-weight: bolder;
  margin-top: 3%;
  margin-left: 4%; }

.sidebar {
  border-left: none !important; }

.logoico {
  width: 100px;
  position: absolute;
  right: 23px;
  top: -22px; }

.logomodal {
  width: 120px;
  margin-left: 40%;
  margin-bottom: 5%; }

.textoModal {
  text-align: center;
  font-size: 1.1em; }

.container-table thead {
  background-color: #f4f4f4; }

.modal div.modal-container {
  border-radius: 20px;
  width: 620px; }

.modal div.modal-container .modal-body button {
  font-size: 1.1rem !important;
  margin-left: 33% !important;
  width: 200px !important;
  margin-top: 15px; }

.screen-contents .details-container .content .content-title {
  border-bottom: 2px solid #59ac54;
  line-height: 4rem; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    border-bottom: 2px solid #59ac54;
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.HeaderContent_jumbotron__2h7zu{
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-image: url(/static/media/via-cargo-cotizador.215764c0.png);
  background-repeat: no-repeat;
  background-position: 65%;
}

@media (max-width: 600px) {
  .HeaderContent_jumbotron__2h7zu {
    background-image: url(/static/media/fondo-mobile.a5c4ed0c.png);
    padding: 4rem 2rem;
  }
}

.HeaderContent_jumbotron-fluid__3CxyE {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}


.HeaderContent_jumbotron2__1fls9 {
  /*padding: 2rem 1rem;*/
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  background-image: url(/static/media/fondo-via-cargo.4e8e0e30.png);
  background-repeat: no-repeat;
  background-position: right 0%;
  background-size: 120%;
}
@media (max-width: 600px) {
.HeaderContent_jumbotron2__1fls9 {
background-image: url(/static/media/fondo-mobile.a5c4ed0c.png);
  padding: 4rem 2rem;
  
}
}

.HeaderContent_row__2k3j9 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
margin-top: 40px;
}

.HeaderContent_card__HZVkY {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.HeaderContent_cardBody__2oYQa {
flex: 1 1 auto;
min-height: 1px;
padding: 1.25rem 0;
text-align: center;
min-height: 313px;
}

.HeaderContent_card-title__3Ouhl img {
  margin: auto;
  display: table;
} 

.HeaderContent_cardText__2M0dC:last-child {
  margin-bottom: 0;
  font-size: 14px;
}

.HeaderContent_card2__2fXNx > .HeaderContent_list-group__38Qe0:last-child .HeaderContent_list-group-item__qvpzh:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
  border: medium solid #FF0509;
}



.HeaderContent_cardTitle2__3Og-- {
  margin-bottom: 0;
  background-color: #9ec64f;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.error-screen {
  padding-bottom: 200px; }
  .error-screen .background {
    padding: 50px 0;
    text-align: center; }
    .error-screen .background img {
      width: 100%;
      max-width: 500px; }
  .error-screen .details-container {
    padding: 0 20px; }
    .error-screen .details-container p {
      text-align: center; }
      .error-screen .details-container p.error {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #59ac54; }

.form-container {
  font-size: 1rem;
  color: #4d4d4f;
  margin-top: 2rem; }
  .form-container input {
    font-size: 1rem;
    height: 2rem; }

.informationForm {
  font-size: 1.2rem;
  margin-top: 2rem; }

.inputForm {
  color: #252324;
  font-size: 1.5rem;
  font-weight: 400;
  height: 3rem; }

.messageForm {
  color: #252324;
  font-size: 1rem;
  font-weight: 400;
  height: 14rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem; }

.labelForm {
  height: auto;
  display: grid;
  margin-top: 0.6rem; }

.sendForm {
  display: flex;
  justify-content: flex-end; }
  .sendForm button {
    font-size: 1.2rem;
    width: 150px; }

@media screen and (max-width: 900px) {
  .informationForm {
    font-size: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem; }
  .sendForm {
    display: flex;
    justify-content: center; }
    .sendForm button {
      font-size: 1.2rem;
      width: 150px; } }

.contact-screen .content-title {
  position: relative; }

.contact-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .contact-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .contact-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

.contact-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center; }

.video-container {
  display: flex;
  align-self: center;
  justify-self: center; }

.button-container {
  display: flex;
  flex-direction: column; }

.contact-button {
  text-decoration: none;
  text-align: center;
  color: black;
  padding: 1px;
  width: 140px;
  border: 3px solid black;
  margin: 10px 0; }
  .contact-button:hover {
    color: black; }

.titel-tex {
  color: #28a745; }

.contact-question {
  color: #468d52;
  font-weight: bolder; }

.tex-content p {
  font-size: 20px; }

.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 55px;
  background-color: #c9c9c9;
  border-radius: 15px 10px 0px 0px; }

from {
  width: 100%;
  height: 500px;
  background-color: #28a745; }

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px; }

.formContainer {
  display: flex;
  flex-direction: column; }

.iframe {
  border: 1px solid #f1f1f2;
  background: white;
  padding: 1rem; }

.contact-form-footer {
  margin-top: 30px;
  font-size: 1.2rem;
  line-height: 1.8rem; }
  .contact-form-footer a {
    color: #59ac54 !important;
    font-weight: bold; }
    .contact-form-footer a:hover {
      text-decoration: underline; }
  .contact-form-footer .form-footer-service {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.8rem; }
    .contact-form-footer .form-footer-service .footer-service-title {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents.contact-screen {
    margin: 0; }
    .screen-contents.contact-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.contact-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.contact-screen .phone a {
    display: block; }
  .screen-contents.contact-screen .phone .hide-on-xs {
    display: none; } }

.error {
  display: block !important; }

.active {
  color: white !important;
  background-color: #6db568 !important; }

.btn {
  border: 1px solid #86b128 !important; }

.btn-primary {
  border: 1px solid #86b128 !important;
  background-color: #86b128 !important;
  color: white !important; }

.btn-secondary {
  border: 1px solid gray !important;
  background-color: gray !important;
  color: white !important; }

.card-header {
  background-color: #6db568 !important;
  color: white !important;
  cursor: pointer; }

.title-product {
  border-bottom: 0px !important;
  line-height: 0 !important; }

.description-product {
  margin: -2rem 0 2em !important; }

.icono {
  width: 3rem; }

.btnSiguiente {
  margin-left: 90%; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

.logoicoservicios {
  width: 100px;
  margin-left: 89%;
  margin-bottom: 31px;
  margin-top: -64px; }

.inputCorto {
  width: 60%; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  margin-left: -5px; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

.modalInterlineado {
  line-height: 1.2em; }

.card-no-price {
  color: red;
  text-decoration: line-through; }

.card-servicio-limitado {
  color: #6db568;
  font-size: 1.2rem; }

.form-check-div {
  display: inline-block;
  margin: 10px;
  padding-left: 33px;
  margin-left: 0px;
  border: 2px solid #666;
  border-radius: 8px; }

.form-check-accept {
  display: inline-block;
  margin: 10px; }

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #666;
  border-radius: 2px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 5px; }

input[type="checkbox"]:checked::before {
  content: "\2714";
  display: block;
  position: absolute;
  top: -6px;
  left: 2px;
  font-size: 22px;
  color: #666; }

.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    top: 0; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    bottom: 0; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%; }

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px; }
  .react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px; }
  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    padding-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    padding-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }
    .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
      border-top-left-radius: 0; }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 2px; }
  .react-datepicker__navigation--next {
    right: 2px; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 85px; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px; }
  .react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6; }

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0; }
  .react-datepicker__navigation-icon--next {
    left: -2px; }
    .react-datepicker__navigation-icon--next::before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      left: -7px; }
  .react-datepicker__navigation-icon--previous {
    right: -2px; }
    .react-datepicker__navigation-icon--previous::before {
      -webkit-transform: rotate(225deg);
              transform: rotate(225deg);
      right: -7px; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px; }
  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: auto; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #f0f0f0; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #216ba5;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #216ba5; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-names {
  margin-bottom: -8px; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90; }

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover,
  .react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #bad9f1;
    color: black; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #1d5d90; }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range ,
  .react-datepicker__quarter-text--in-selecting-range ,
  .react-datepicker__year-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
    background-color: #f0f0f0;
    color: #000; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5; }

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    right: -16px;
    top: 0; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle; }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\D7"; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto; }

.table-paradas {
  height: 280px;
  overflow-y: scroll; }

table.unstyledTable {
  width: 500px; }

table.unstyledTable td,
table.unstyledTable th {
  border: 0px solid #aaaaaa; }

table.unstyledTable tbody td {
  font-size: 13px; }

table.unstyledTable thead {
  background: #dddddd;
  border-bottom: 1px solid #7e7e7e; }

table.unstyledTable thead th {
  font-size: 13px;
  font-weight: bold; }

@media screen and (max-width: 900px) {
  .table.unstyledTable {
    width: 300px; } }

.dropdown-toggle {
  width: 100%;
  text-align: left; }

.dd button {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #d2d2d2 !important; }

.cardd {
  border: none;
  height: 380px;
  background-color: rgb(255, 255, 255);
  margin-top: 120px;
}

.logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.logo img {
  position: relative;
}

.input-group input:focus {
}

.password {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  padding-bottom: 15px;
  width: 100%;
}

.password a {
  text-decoration: none;
  color: rgb(0, 72, 206);
}

.button {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px 5px;
  background-color: rgb(0, 160, 0);
  color: #fff;
}

.button:hover {
  background-color: rgb(0, 175, 0);
}

.bg-login {
  background-image: url(/static/media/bgLogin.deb6bb42.jpg);
  background-repeat: no-repeat;
  height: 800px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-formulario {
  margin-bottom: 20px;
}

.titulo-formulario h3 {
  width: 90%;
  color: rgb(0, 156, 0);
  margin-bottom: 10px;
  margin-left: 40px;
  border-bottom: 1px solid rgb(0, 156, 0);
}
.formulario {
  color: black;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 20px 30px 1px rgb(0 0 0 / 12%);
  border-radius: 15px 15px;
}
.campo {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.campoNa {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.campoNa input {
  height: 40px;
  border-radius: 10px 10px;
  border: 1px solid gray;
  outline: 0px;
}

.campoNa .input-apellido {
  width: 100%;
}

.input-nombre {
  width: 95%;
}

.campoNa label {
  /* margin-bottom: -20px;*/
  margin-left: 10px;
  margin-top: 5px;
}

.campoNa p {
  color: rgb(0, 156, 0);
  margin-top: 5px;
  margin-left: 10px;
}

.documento {
  cursor: pointer;
  width: 95%;
  height: 40px;
  border-radius: 10px 10px;
}

.input-direccion {
  width: 80%;
}

.recaptcha-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.terminos-condiciones {
  display: flex;
  flex-direction: row;
}

.checkBox {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.boton-crear-cuenta {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.boton-crear-cuenta input {
  height: 40px;
  border: none;
  background-color: #59ac54;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;
  margin-right: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
}

.boton-crear-cuenta input:hover {
  background-color: #478344;
}

.validacion-error p {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .titulo-formulario {
    width: 100%;
  }

  .titulo-formulario h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  .campo {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .campoNa input {
    width: 100%;
  }
  .campoNa .input-apellido {
    width: 90%;
  }
}

@media screen and (max-width: 380px) {
  .containerFormm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formulario {
    width: 90%;
  }
  .campo-select {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .campo-select .select-campo select {
    /*width: 150px;*/
    width: auto;
    min-width: auto;
  }
}

.recover-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recover-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-recover-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 20px;
}

.title-recover-container h4 {
  color: rgb(27, 151, 27);
}

.text-recover-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.input-recover-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;
  margin-top: 20px;
}
.input-recover-container label {
  margin-left: -35%;
}
.input-recover-container input {
  width: 60%;
  height: 40px;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 10px 10px;
  outline: 0;
}

.button-recover-container {
  display: flex;
  justify-content: flex-end;

  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-recover-container input {
  border: 0;
  background-color: rgb(129, 179, 55);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-recover-container input:hover {
  background-color: rgb(120, 160, 60);
}

.recover-input {
  padding: 0.375rem 0.75rem;
}

.validacion-error p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(255, 0, 0);
}

@media screen and (max-width: 770px) {
  .title-recover-container h4 {
    font-size: 18px;
  }
  .text-recover-container {
    padding: 5px 0;
    height: 92px;
  }
  .text-recover-container p {
    font-size: 14px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-recover-container label {
    margin-left: 10px;
  }
  .input-recover-container input {
    width: 100%;
  }
  .button-recover-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  .text-recover-container {
    height: 92px;
    margin-top: 25px;
    padding-bottom: 35px;
  }
  .title-recover-container h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  .text-recover-container p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-recover-container label {
    margin-left: 10px;
    text-align: center;
  }
  .input-recover-container input {
    width: 100%;
  }
  .button-recover-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 40px;
}

.title-confirm-container h4 {
  color: rgb(27, 151, 27);
  text-align: center;
}

.text-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.text-confirm-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}

.button-confirm-container {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-confirm-container input {
  border: 0;
  background-color: rgb(25, 163, 71);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-confirm-container input:hover {
  background-color: rgb(19, 191, 76);
}

.confirm-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 780px) {
  .title-confirm-container {
    width: 70%;
    margin-bottom: 20px;
  }

  .title-confirm-container h4 {
    text-align: center;
    font-size: 22px;
    margin-top: 10px;
  }
  .text-confirm-container {
    width: 70%;
    margin-bottom: 10px;
  }
  .text-confirm-container p {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .title-confirm-container {
    width: 70%;
    margin-bottom: 28px;
  }

  .title-confirm-container h4 {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  .text-confirm-container {
    width: 70%;
    margin-bottom: 10px;
  }
  .text-confirm-container p {
    font-size: 15px;
    text-align: center;
  }
}

@media screen and (max-width: 365px) {
  .title-confirm-container {
    width: 70%;
    margin-bottom: 58px;
  }

  .title-confirm-container h4 {
    text-align: center;
    font-size: 16px;
    margin-top: 40px;
  }
  .text-confirm-container {
    width: 70%;
    margin-bottom: 10px;
  }
  .text-confirm-container p {
    font-size: 14px;
    text-align: center;
  }
}

.confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 5px;
}

.text-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.text-confirm-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}
.text-confirm-container h4 {
  color: rgb(27, 151, 27);
  text-align: center;
}
.button-confirm-container {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-confirm-container input {
  border: 0;
  background-color: rgb(25, 163, 71);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-confirm-container input:hover {
  background-color: rgb(19, 191, 76);
}

.confirm-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 480px) {
  .text-confirm-container h4 {
    font-size: 20px;
  }
}

.logo-via-compras {
  width: 170px; }

.header-gradient {
  min-height: 3rem;
  width: 100%;
  background: linear-gradient(left, #008a00, #00a100, #30c90e); }

.header {
  width: 100%;
  align-items: center;
  margin: 0.2rem 0;
  position: relative; }
  .header .logo {
    padding: 1rem 0 1rem 5rem;
    display: inline-block; }
    .header .logo img {
      width: 320px; }
  .header .menu-container {
    display: inline-block;
    position: absolute;
    right: 5rem;
    top: 15px; }

.menu-separator {
  color: grey; }

.menu-link {
  display: flex;
  align-items: center; }

.hamburger-menu {
  display: none; }
  .hamburger-menu .hamburgerIcon {
    cursor: pointer;
    width: 30px;
    height: 30px; }

.spinner {
  margin-top: 10px;
  height: 20px;
  line-height: 30px; }

.modal {
  opacity: 1;
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: opacity 1s ease-in;
  pointer-events: auto;
  overflow-y: auto; }
  .modal div.modal-container {
    opacity: 1;
    width: 700px;
    min-height: 200px;
    margin: 10% auto;
    position: relative;
    padding: 30px;
    background: #f1f1f2; }
    @media screen and (max-width: 900px) {
      .modal div.modal-container {
        width: 100%; } }
    .modal div.modal-container .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c2c2c2;
      cursor: pointer;
      font-size: 14px;
      border-radius: 30px;
      font-weight: bold;
      color: white;
      height: 30px;
      width: 30px;
      padding: 3px 0 0 3px; }
      .modal div.modal-container .close-button:hover {
        background: #929496; }
    .modal div.modal-container .modal-body p {
      font-size: 1.2rem;
      line-height: 1rem; }
    .modal div.modal-container .modal-body button {
      font-size: 1.2rem; }
    .modal div.modal-container .modal-body .modal-img {
      width: 95%;
      margin-top: 15px;
      margin-left: 2.5%; }

.modal-popup {
  opacity: 1;
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: opacity 1s ease-in;
  pointer-events: auto;
  /*overflow-y: auto;*/
  overflow: auto; }
  .modal-popup div.modal-container-popup {
    opacity: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    padding: 15px;
    /*background: #f1f1f2;*/ }
    @media screen and (max-width: 900px) {
      .modal-popup div.modal-container-popup {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; } }
    .modal-popup div.modal-container-popup .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c2c2c2;
      cursor: pointer;
      font-size: 14px;
      border-radius: 30px;
      font-weight: bold;
      color: white;
      height: 30px;
      width: 30px;
      padding: 3px 0 0 3px; }
      .modal-popup div.modal-container-popup .close-button:hover {
        background: #929496; }
    .modal-popup div.modal-container-popup .modal-body p {
      font-size: 1.2rem;
      line-height: 1rem; }
    .modal-popup div.modal-container-popup .modal-body button {
      font-size: 1.2rem; }
    .modal-popup div.modal-container-popup .modal-body .modal-img {
      width: 95%;
      margin-top: 15px;
      margin-left: 2.5%; }

.carousel-img {
  object-fit: cover;
  object-position: right; }

.credits {
  padding: 10px 0;
  text-align: center;
  background: #181c1e;
  color: white; }
  .credits a {
    color: white; }
    .credits a:hover {
      text-decoration: none;
      font-weight: bold; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .header .logo {
    padding: 1rem 0 1rem 2rem; }
    .header .logo img {
      width: 260px; }
  .header .menu-container {
    right: 2rem;
    top: 5px; } }

@media screen and (max-width: 900px) {
  .hamburger-menu {
    display: inline; }
    .hamburger-menu .floating-menu {
      position: absolute;
      top: 65px;
      left: 0;
      width: 100%;
      background: #008a00;
      z-index: 100; }
      .hamburger-menu .floating-menu .fm-item {
        padding: 5px 15px;
        border-bottom: 1px solid white;
        color: white; }
        .hamburger-menu .floating-menu .fm-item a {
          font-weight: bold;
          color: white !important; }
          .hamburger-menu .floating-menu .fm-item a:visited {
            color: white !important; }
          .hamburger-menu .floating-menu .fm-item a div {
            position: relative; }
            .hamburger-menu .floating-menu .fm-item a div .chevron {
              position: absolute;
              right: 20px;
              top: -5px;
              font-size: 20px; }
        .hamburger-menu .floating-menu .fm-item.top-border {
          border-top: 1px solid white; }
    .hamburger-menu .floating-menu-overlay {
      z-index: 50;
      background: rgba(40, 40, 40, 0.5);
      position: absolute;
      top: 82px;
      width: 100%;
      left: 0; }
  .menu-container {
    display: none !important; }
  .header .logo {
    flex: inherit;
    padding: 0rem 0rem 0rem 1rem;
    width: 90%; }
  .header .logo img {
    width: 200px; }
  .header-gradient {
    display: none; }
  .legend .carousel-content {
    display: none; }
  .carousel .slide .legend {
    position: inherit;
    display: block;
    width: 100% !important;
    position: inherit;
    margin-left: 0;
    padding: 0; }
  .legend .sidebar {
    margin: 0;
    width: 100%; }
  .carousel.carousel-slider {
    height: 100%; }
  .summary-bar {
    display: none; }
  .footer .terms {
    margin: 0;
    padding: 0; } }

@media screen and (max-width: 400px) {
  .header .logo {
    flex: inherit;
    padding: 0rem 0rem 0rem 1rem;
    width: 85%; } }

@media screen and (min-width: 1920px) {
  .details-container {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto; } }

.change-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-change-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
}

.title-change-container h4 {
  color: rgb(27, 151, 27);
}

.text-change-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.input-change-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;
  margin-top: 10px;
}
.input-change-container label {
  margin-left: -55%;
}
.input-change-container input {
  width: 80%;
  height: 40px;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 10px 10px;
  outline: 0;
}

.input-change-container p {
  width: 80%;
  color: rgb(129, 179, 55);
  margin-bottom: -8px;
}

.button-change-container {
  display: flex;
  justify-content: flex-end;

  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-change-container input {
  border: 0;
  background-color: rgb(129, 179, 55);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 200px;
  height: 40px;

  transition: 0.3s;
}

.button-change-container input:hover {
  background-color: rgb(120, 160, 60);
}

.change-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 770px) {
  .title-recover-container h4 {
    font-size: 18px;
  }
  .text-change-container {
    padding: 5px 0;
    height: 92px;
  }
  .text-change-container p {
    font-size: 16px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-change-container label {
    margin-left: 10px;
  }
  .input-change-container input {
    width: 100%;
  }
  .input-change-container p {
    font-size: 13px;
    text-align: center;
  }
  .button-change-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 490px) {
  .title-recover-container h4 {
    font-size: 16px;
  }
  .text-change-container {
    padding: 5px 0;
    height: 92px;
  }
  .text-change-container p {
    font-size: 14px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-change-container label {
    text-align: center;
    font-size: 16px;
  }
  .input-change-container input {
    width: 100%;
  }
  .input-change-container p {
    font-size: 10px;
    text-align: center;
  }
  .button-change-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 360px) {
  .title-recover-container h4 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
  }
  .text-change-container {
    padding: 5px 0;
    height: 92px;
  }
  .text-change-container p {
    font-size: 12px;
    margin-bottom: 30px;
    text-align: center;
  }
  .input-change-container label {
    text-align: center;
    font-size: 14px;
  }
  .input-change-container input {
    width: 100%;
    height: 30px;
  }
  .input-change-container p {
    font-size: 10px;
    text-align: center;
  }
  .button-change-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.category-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-category-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 40px;
}

.title-category-container h4 {
  color: rgb(27, 151, 27);
}

.text-category-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.text-category-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}

.input-category-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 70%;
  margin-bottom: 10px;
}

.input-category-container input {
  width: 30%;
  height: 40px;
  border: none;
  border-radius: 10px 10px;
  background-color: rgb(135, 202, 35);
  color: white;
  font-weight: 600;
  margin-right: 12px;
  transition: 0.3s;
}

.input-category-container input:hover {
  background-color: rgb(149, 235, 21);
}
.input-category-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input-category-checkbox-container input {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-bottom: 13px;
}

.button-category-container {
  display: flex;
  justify-content: flex-end;

  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-category-container input {
  border: 0;
  background-color: rgb(25, 163, 71);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-category-container input:hover {
  background-color: rgb(19, 191, 76);
}

.category-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 995px) {
  .title-category-container h4 {
    font-size: 20px;
  }
  .sub-title-category-container h5 {
    font-size: 18px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 16px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 769px) {
  .title-category-container h4 {
    font-size: 20px;
  }
  .sub-title-category-container {
    width: 70%;
  }
  .sub-title-category-container h5 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
  }
  .text-category-container {
    width: 70%;
    padding-bottom: 10px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 25px;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 14px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 595px) {
  .title-category-container h4 {
    font-size: 18px;
    text-align: center;
  }
  .sub-title-category-container {
    width: 70%;
  }
  .sub-title-category-container h5 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 45px;
  }
  .text-category-container {
    width: 70%;
    padding-bottom: 10px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 35px;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }

  .text-category-container2 {
    width: 70%;
  }
  .text-category-container2 p {
    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 14px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}

.banner-precaucion {
  background: #1d2427;
  text-align: center;
  color: white;
  height: 22px;
  font-size: 1em;
}
@media screen and (max-width: 768px) {
  .banner-precaucion {
    height: 12px;
    font-size: 0.55em;
  }
}

.warning-icon {
  width: 18px;
  margin-right: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .warning-icon {
    width: 10px;
    margin-right: 4px;
    margin-top: 1px;
  }
}

* html,
body,
select {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background: white !important; }

button {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background: white; }

/* Form generals */
.basic-select .select__control {
  border-radius: 0; }

